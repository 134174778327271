<template>
    <li class="member-item list-group-item p-0">
        <div class="d-flex justify-content-between align-items-center p-3">
            <img class="rounded-circle mr-3"
                 v-bind:alt="ticketOwner.name"
                 v-bind:src="`${ apiUrl }/${ apiVersion }/contact/${ ticketOwner.countryCode }${ ticketOwner.phone }/avatar`">
            <div class="flex-grow-1 text-right">
                <h5 class="font-weight-bolder mb-1">
                    {{ ticketOwner.name }}
                </h5>
                <p class="mb-0">
                    +{{ ticketOwner.countryCode }}{{ ticketOwner.phone }}<br>
                    <small>Joined {{ ticketCreated }}</small>
                </p>
            </div>
        </div>
        <div class="text-right bg-light px-2 py-1">
            <button class="btn btn-sm btn-success rounded-pill">
                👍 Confirmed
            </button>
        </div>
    </li>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
import { formatDistanceToNow, parseISO, } from "date-fns";
export default {
    name: "TicketItem",
    props: {
        "ticket": {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            apiUrl, apiVersion,
        };
    },
    computed: {
        ticketOwner () {
            return this.ticket.owner;
        },

        ticketCreated () {
            return formatDistanceToNow(parseISO(this.ticket.createdAt), { addSuffix: true });
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.member-item {
    .rounded-circle {
        width: 64px;
        height: 64px;
        object-fit: cover;
    }
}
</style>
